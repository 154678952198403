.feature-grids {
  gap: 10px;
  margin: 5px 40px;
}

.feature-grid-item h2 {
  color: #000000;
  padding: 10px 0 10px;
}

.feature-grid-item h3 {
  font-size: 25px;
  color: var(--dark-blue);
  padding-bottom: 10px;
}

/* .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper{
  width: 200px;
} */
.fee-header {
  .ant-radio-button-wrapper {
    width: 90px;
  }
}
.total_fees {
  display: flex;
  gap: 20px;
  padding: 10px;
  justify-content: space-between;

  .fee-details-heading {
    margin: 0 0 25px 0;
    font-size: 16px;
  }
  .back_container {
    display: flex;
    width: 33%;
    justify-content: space-around;
    align-items: center;
    padding: var(--padding10);
    border-radius: var(--radius10);
    box-shadow: var(--shadow);
    flex-direction: column;
    padding: 25px;
    p {
      margin: 0;
    }
    h1 {
      margin: 0;
      font-size: 25px;
    }
  }
  .bg1 {
    background-color: rgb(223, 237, 250);
  }
  .bg2 {
    background-color: #e8f8ee;
  }
  .bg3 {
    background-color: #fedada;
  }
  .bg4 {
    background-color: rgb(243, 228, 239);;
  }
  .back_container1 p {
    font-weight: bold;
    font-size: 15px;
  }
  .middleline {
    background-color: rgb(189, 189, 189);
    width: 1px;
    height: auto;
  }
  .ant-radio-button-wrapper > .ant-radio-button:hover {
    .anticon-check-circle {
      color: green;
      background-color: green;
    }
  }
}
/* .Table {
  overflow-x: scroll;
  margin-top: 20px;
} */

/* table {
  width: 100%;
  table-layout: fixed;
  min-width: 100px;
  text-align: center;
  border-collapse: separate;
 
overflow-x: scroll;
}

thead th {
  font-size: 16px;
  padding-bottom: 15px;
  border-bottom: 2px solid #000000de !important;
}

*/
:where(.css-dev-only-do-not-override-acm2ia).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-acm2ia).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td,
:where(.css-dev-only-do-not-override-acm2ia).ant-table-wrapper tfoot > tr > th,
:where(.css-dev-only-do-not-override-acm2ia).ant-table-wrapper tfoot > tr > td {
  position: relative;
  padding: 5px 6px;
  overflow-wrap: break-word;
}

tbody tr td {
  background-color: white;
  padding: 15px 0;
  font-size: 13px;
  border: 10px 10px;
}

tbody tr td:nth-child(1) {
  border-radius: 10px 0 0 10px;
}

.fee-header {
  display: flex;
  justify-content: space-between;
}

tbody tr #six-tab:nth-child(6) {
  border-radius: 0px 10px 10px 0px;
}

.card {
  padding-bottom: 0px;
}

@media screen and (max-width: 1200px) {
  .Table {
    width: 27rem;
  }

  .feature-grids {
    grid-template-columns: repeat(1, 1fr);
    margin: 20px 0px;
  }
}

@media screen and (max-width: 768px) {
  .Table {
    width: 45rem;
  }
}

/*  table header*/
@media screen and (max-width: 560px) {
  .Table {
    width: 21rem;
  }
}
