.studies-widget {
  justify-content: space-between;
  padding: 10px 0;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  /* width:100px */
}

.tabs-btn {
  padding: 0.7rem 2rem;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 8px;
  border: 0;
  background: #d2cfcf;
  color: #888686;
}

.submit-button {
  background-color: #172b4d;
  color: #fff;
}

.results-title {
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
}

.containerTabs {
  padding: 10px;
}

:where(.css-dev-only-do-not-override-acm2ia).ant-tabs>.ant-tabs-nav,
:where(.css-dev-only-do-not-override-acm2ia).ant-tabs>div>.ant-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
  /* width: 180px; */
}

.tabs-btn-active {
  background: #172b4d;
  color: white;
}

.ant-btn-default:not(:disabled):hover {
  color: white;
  border-color: #172b4d;
}

.study-btns button {
  background: #96b2fa;
  border-radius: 40px;
  color: #ffffff;
  border: 0;
  padding: 10px 40px;
}

.studies-btns {
  display: flex;
  border-bottom: 1px solid #000000;
}

.studies-btns button {
  flex: 1;
  background-color: none;
  border: 0;
  padding: 10px 0;
  color: var(--dark-grey);
}

.study-btn-active {
  background-color: #ffffff;
  color: #000000 !important;
}

.study-itemsFlex {
  width: 50%;
  margin: 20px 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.study-itemsFlex button {
  background: #ffffff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: 0;
  font-size: 17px;
}

.exam-icon {
  font-size: 20px;
  color: #8493e3;
  margin-left: 20px;
}



.vertical-scroll-modal {
  .modal-content {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* media queries */
@media (max-width: 500px) {
  .study-itemsFlex {
    width: 100%;
    margin: 20px 0px;
    grid-template-columns: repeat(1, 1fr);
  }
}